@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Outfit", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollCustomStyle {
  scroll-behavior: smooth;
  overflow-y: auto;
}

.scrollCustomStyle::-webkit-scrollbar {
  width: 6px;
}

.scrollCustomStyle::-webkit-scrollbar-thumb {
  background: #e5e7f2;
  border-radius: 10px;
}

.scrollCustomStyle::-webkit-scrollbar-track {
  margin-top: 20px;
}

.font-Aleo {
  font-family: "Aleo", sans-serif !important;
}

.font-Lora {
  font-family: "Lora", sans-serif !important;
}

.font-LibreBaskerville {
  font-family: "Libre Baskerville", sans-serif !important;
}

.font-Roxborough {
  font-family: "Roxborough CF", sans-serif !important;
}
